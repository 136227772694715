import React, { Component } from 'react';
import { throttle } from 'lodash';

import './background-canvas-component.scss';

class BackgroundCanvas extends Component {
    constructor() {
        super();
        this.canvasBehaviour = this.canvasBehaviour.bind(this);
        this.resize = this.resize.bind(this);

        this.state = {
            frameNumber: 0,
            canvas: {},
            ctx: {},
            lastPositions: [],
            lastDirections: [],
            lastlastDirections: [],
            circleRadiuses: []
        };
    }

    lerp(value1, value2, amount) {
        amount = amount < 0 ? 0 : amount;
        amount = amount > 1 ? 1 : amount;
        return value1 + (value2 - value1) * amount;
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize);

        let c = document.getElementById('my_canvas');
        let ctx = c.getContext('2d');
        this.setState({
            canvas: c,
            ctx: ctx
        });
        c.width = window.innerWidth;
        c.height = document.documentElement.scrollHeight;

        window.requestAnimationFrame(() => {
            this.canvasBehaviour(0);
        });
    }

    componentDidUpdate() {
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize = throttle(() => {
        if (!this.state.canvas) return;
        this.state.canvas.width = window.innerWidth;
        this.state.canvas.height = document.documentElement.scrollHeight;
    }, 50);

    spawnCircle(frameNumber, idx, isFilled = false, initialPosX = 0, initialPosY = 0) {
        if (this.state.lastPositions.length < idx * 2 + 1) {
            this.state.lastPositions.push(initialPosX);
            this.state.lastPositions.push(initialPosY);
            this.state.lastDirections.push(0);
            this.state.lastDirections.push(0);
            this.state.lastlastDirections.push(0);
            this.state.lastlastDirections.push(0);
            this.state.circleRadiuses.push(Math.random() * 50);
        }

        let gradient = this.state.ctx.createLinearGradient(
            this.state.lastPositions[idx * 2] - 17,
            this.state.lastPositions[idx * 2 + 1] - 17,
            this.state.lastPositions[idx * 2] + 17,
            this.state.lastPositions[idx * 2 + 1] + 17
        );

        gradient.addColorStop('0', '#DC3148');
        gradient.addColorStop('1.0', '#5B49B0');
        this.state.ctx.strokeStyle = gradient;
        this.state.ctx.fillStyle = gradient;

        if (frameNumber % 25 == 0) {
            this.state.lastlastDirections[idx * 2] = this.state.lastDirections[idx * 2];
            this.state.lastlastDirections[idx * 2 + 1] = this.state.lastDirections[idx * 2 + 1];
            this.state.lastDirections[idx * 2] = Math.random() * 4 - 2;
            this.state.lastDirections[idx * 2 + 1] = Math.random() * 4 - 2;
            frameNumber = 0;
        }
        this.state.lastPositions[idx * 2] += this.lerp(
            this.state.lastlastDirections[idx * 2],
            this.state.lastDirections[idx * 2],
            frameNumber / 25.0
        );
        this.state.lastPositions[idx * 2 + 1] += this.lerp(
            this.state.lastlastDirections[idx * 2 + 1],
            this.state.lastDirections[idx * 2 + 1],
            frameNumber / 25.0
        );

        this.state.ctx.beginPath();
        this.state.ctx.arc(
            this.state.lastPositions[idx * 2],
            this.state.lastPositions[idx * 2 + 1],
            this.state.circleRadiuses[idx],
            0,
            2 * Math.PI
        );
        if (isFilled) {
            this.state.ctx.fill();
        } else {
            this.state.ctx.stroke();
        }
        this.state.ctx.closePath();

        return frameNumber;
    }

    canvasBehaviour(frameNumber) {
        // this.setState({ frameNumber: this.state.frameNumber + 1 });
        frameNumber = frameNumber + 1;
        this.state.ctx.clearRect(0, 0, this.state.canvas.width, this.state.canvas.height);
        this.state.ctx.lineWidth = 5;
        // Fill with gradient
        for (let i = 0; i < 10; i += 2) {
            frameNumber = this.spawnCircle(
                frameNumber,
                i,
                false,
                Math.floor(Math.random() * this.state.canvas.width),
                Math.floor(Math.random() * this.state.canvas.height)
            );
            frameNumber = this.spawnCircle(
                frameNumber,
                i + 1,
                true,
                Math.floor(Math.random() * this.state.canvas.width),
                Math.floor(Math.random() * this.state.canvas.height)
            );
        }

        window.requestAnimationFrame(() => {
            this.canvasBehaviour(frameNumber);
        });
    }

    render() {
        return (
            <div className="background-canvas__wrapper">
                <canvas id="my_canvas" />
            </div>
        );
    }
}

export default BackgroundCanvas;
