import React, { Component } from 'react';
import { Link } from 'gatsby';
import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import './layout.scss';

let homeIcon = require('../images/home.png');
let internIcon = require('../images/internationalization.png');
let roInternIcon = require('../images/ro_internationalization.png');
let enInternIcon = require('../images/en_internationalization.png');

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lng: 'en'
        };

        this.switchLanguage = this.switchLanguage.bind(this);
    }

    switchLanguage(i18n) {
        if (this.state.lng === 'en') {
            this.setState({ lng: 'ro' });
            i18n.changeLanguage('ro');
        } else {
            this.setState({ lng: 'en' });
            i18n.changeLanguage('en');
        }
    }

    render() {
        const { t, i18n } = this.props;
        return (
            <div className="main-page__wrapper">
                <Helmet title="Government of Children" />
                {/* <header> <h1> {i18n.t('main_title')} </h1></header> */}
                <nav className="main-menu">
                    <Link to="/" className="home" activeClassName="is-active">
                        <img src={homeIcon} />
                    </Link>
                    <Link to="/explore" activeClassName="is-active">
                        <span className="underline" />
                        {i18n.language === 'ro' ? 'Explorează' : 'Explore'}
                    </Link>
                    <Link to="/become" activeClassName="is-active">
                        <span className="underline" />
                        {i18n.language === 'ro' ? 'Participă' : 'Become'}
                    </Link>
                    <Link to="/inhabit" activeClassName="is-active">
                        <span className="underline" />
                        {i18n.language === 'ro' ? 'Vizionează' : 'Inhabit'}
                    </Link>
                    <button className="change-language" onClick={() => this.switchLanguage(i18n)}>
                        {i18n.language === 'ro' ? <img src={roInternIcon} /> : <img src={enInternIcon} />}
                    </button>
                </nav>
                <main>{this.props.children}</main>
                {/* <footer>© {new Date().getFullYear()}</footer> */}
            </div>
        );
    }
}

export default withTranslation()(Layout);
