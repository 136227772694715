import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, reactI18nextModule } from 'react-i18next';

const resources = {
    en: {
        translation: {
            main_title: 'Government of Children',

            goc_ambassadors_1: 'Do you want to become a GoC Ambassador? Complete the ',
            goc_ambassadors_2: 'questionnaire',
            goc_ambassadors_3: ' and we will contact you soon.',

            donate:
                'Do you want to support Government of Children to reach as many kids as possible? You can donate in the account …',
            share: 'Feel free to reach us out',

            become_title_1: 'You too can be part of Government of Children!',
            become_title_2: 'Record your own visionary proposals below!',
            become_desc:
                'Are you a visionary child? Or simply in the mood for a brief civic imagination session? Become part of Government of Children by completing this form and activating your webcam to respond some of the most frequent contemporary questions. You will activate your webcam and respond to a chosen question. Then, few days later, you will find your responses in the Explore dimension of web playground and you will be an official virtual member of Government of Children. Enjoy!',
            become_desc_2: 'The timetable for project submissions is:',
            become_faza_1: 'PHASE 1 - submit your own vision as a leader on the website by 30 May 2020',
            become_faza_2:
                "PHASE 2 - On Children's day, June 1st, 2020, you will be able to explore all the visions submitted on the project's website (Explore section)",
            become_faza_3:
                'PHASE 3 - All children participating in this call will be included in the casting for Government of Children 2, a hyper-ambitious film project',
            explore: 'Explore',
            name_placeholder: 'Name and surname(confidential)',
            nickname_placeholder: 'Your public nickname(public)',
            age_placeholder: 'Age(public)',
            role_placeholder:
                'What role would you like to occupy in Government of Children(e.g. Ministry of Education, President)',
            country_placeholder: 'Country you represent(public)',
            thematic_placeholder: "Thematic you're interested in (public)",
            question_placeholder: 'Question you would like to answer (public)',
            parents_placeholder: 'Parents of Legal Tutors names(confidential)',
            phone_placeholder: ' Phone number (confidential)',
            email_placeholder: 'Email(confindential)',
            agree_label_1: 'Accepting ',
            agree_label_2: 'participation terms and conditions ',
            agree1_label_3: ' on Gonvernment of Children platform by parents (confindential)',
            agree2_label_3: ' on Gonvernment of Children platform by children (confindential)',
            start_recording_but: 'Prepare your answer, activate the webcam and record your vision.',
            recording: 'We are currently recording your view...',

            success_upload_message:
                'Your registration was received and it is in approval right now! Thanks for being part of the Government of children! Meanwhile, you can follow what the other children have to say.',

            inhabit_desc:
                'Did you ever imagine how the landscapes, urbanscapes and waterscapes might look like in the next century? TANGIBLE UTOPIAS is the multilayered VR world of the Government of Children transmedia universe and invites you to have a sneak peek into the imagination of hundreds of children regarding the future.Using the archived visions of the GoC children as inspiration, we aimed to design a co- created computer- generated futuristic cities and planetary glimpses. TANGIBLE UTOPIAS expands each vision into an exploratory virtual world that you are invited to inhabit in the most conscious manner. The VR journey will gradually include numerous layers and pioneering technologies: WebVR, EEG- controlled experiences in which users can influence the future cities with their mindscapes. Inhabit the multiple futures of the world as envisaged by children and let us know how would you customize them in the most balanced manner. ',
            director_statement:
                '“As a child, I imagined the government as a space of freedom where people of all ages, genders and professions are entitled to voice their opinions. Children have no rights to vote in our societies, however their vision if heard and taken seriously, can shape their own community in unprecedented ways. The core of this transmedia documentary is to empower children to share their visions.How do they perceive the core- problems of our society? What solutions would they envisage? How would they imagine the future of architecture, education, transportation? A personal incursion into the micro- history of our contemporary issues. In the context of March for our Lives, this initiative becomes not only complementary, but vital.Children matter.More than “delightfully sweet creatures”, they matter as young leaders.Countries like Turkey or Denmark allow one day per year to invite children in the Parliament house.the gesture, although symbolic, might be borrowed in more nations.Our goal now is - how would Italian children respond to this civic imagination challenge ? “We are called to be architects of the future” said once Buckminster Fuller and I feel this applies flawlessly to our ordinary life, as children, teenagers, adults or seniors.I am highly delighted to bring my contribution to a daringly playful cinematic vision du monde.Conceived as a long - term transmedia storytelling project, this is an artistic, educational, social and  visionary statement of our times.”  - Ioana Mischie',
            team_desc:
                'The team of Government of Children 3D reunites a transdisciplinary group of multi-awarded visionary thinkers, cinematic storytellers film creators, technologistsi,  artists, ethicians and educators.'
        }
    },
    ro: {
        translation: {
            main_title: 'Guvernul Copiilor',

            goc_ambassadors_1: 'Vrei să devii Ambasador GoC? Completează ',
            goc_ambassadors_2: 'chestionarul',
            goc_ambassadors_3: ' și te vom contacta curând.',

            donate:
                'Vrei să sprijini Guvernul Copiilor să continue și să reunească mult mai mulți copii? Poți dona în contul ...',
            share: 'Ne poți scrie sau urmări la',

            become_title_1: 'Și tu poți face parte din Government of Children!',
            become_title_2: 'Arhivează-ți propria viziune cu privire la viitorul societății',
            become_desc:
                'Ai între 6 și 12 ani și o sumedenie de idei vizionare pentru un viitor mai constructiv? Sau poate vrei doar să exersezi imaginația civică? Te invităm să devii parte din Guvernul Copiilor - vei putea contribui în timp real cu propuneri pentru o societate mai avansată și vei putea face cunoștință cu întreaga comunitate GoC.  Cum arată viitorul arhitecturii, al educației, al sănătății în viziunea ta? Cum ai rezolva probleme globale cum ar fi violența, poluarea sau inegalitatea socială? Viziunile tale arhivate via webcam vor fi ulterior integrate de către echipa proiectului în platforma exploratorie a proiectului și vei deveni oficial membru virtual al Guvernului Copiilor. Bine ai venit! ',
            become_desc_2: 'Calendarul înscrierilor în proiect:',
            become_faza_1: 'FAZA 1 - înscrie-ți propria viziune de lider pe website până pe 30 mai 2020',
            become_faza_2:
                'FAZA 2 - de Ziua Copilului, pe 1 iunie 2020, vei putea explora toate viziunile înscrise pe website- ul proiectului(secțiunea Explore)',
            become_faza_3:
                'FAZA 3 - toți copiii care participă la acest apel vor fi incluși în casting-ul pentru Government of Children 2, un proiect cinematografic hyper- ambițios',
            explore: 'Explorează',
            name_placeholder: 'Numele și prenumele tău(confidențial)',
            nickname_placeholder: 'Nickname-ul care ți-ar plăcea să apară pe website(public)',
            age_placeholder: 'Vârstă(public)',
            role_placeholder: 'Ce rol ți-ar plăcea să ai în Guvernul Copiilor? (Ex. Ministrul Educației / Președinte)',
            country_placeholder: 'Bifează țara pe care o reprezinți(public)',
            thematic_placeholder: 'Alege-ți tema de interes (public)',
            question_placeholder: 'Alege-ți întrebarea la care ți-ar plăcea să răspunzi (public)',
            parents_placeholder: 'Numele părinților sau ai tutorilor legali (confidential)',
            phone_placeholder: ' Telefon(confidențial)',
            email_placeholder: 'Email(confindențial)',
            agree_label_1: 'Acceptarea ',
            agree_label_2: 'termenilor și condițiilor participării ',
            agree1_label_3: ' pe website-ul GoC de către părinți (confindențial)',
            agree2_label_3: ' pe website-ul GoC de către participanții copii (confindențial)',
            start_recording_but: 'Pregătește-ți răspunsul și activează-ți webcam-ul pentru a înregistra viziunea.',
            recording: 'Acum înregistrăm viziunea ta...',

            success_upload_message: 'Mulțumim mult, viziunea ta a fost înscrisă cu succes!',

            inhabit_desc:
                'Tangible Utopias este componenta de realitate virtuală a universului transmedia Government of Children - care arhivează și dezvoltă viziunile copiilor într-un oraș al viitorului computer-generated. Astfel, Tangible Utopias devine o lume exploratorie în care ești invitat să descoperi multiplele scenarii de viitor posibile și să le personalizezi. Călătoria VR va include, treptat, numeroase tehnologii de pionierat precum experiențe controlate cu ajutorul EEG, în care userii pot modela orașele viitorului prin modul în care gândesc. Te invităm să locuiești pentru o clipă în lumile imaginate și co- create de către copiii GoC! ',

            director_statement:
                ' “Când eram copil, mi-am imaginat guvernul ca un spațiu liber în care oameni de toate vârstele, genurile și profesiile își pot exprima o opinie și se pot ajuta reciproc pentru a o aduce la viață.  În timp, am conștientizat că societatea are cu totul alte configurații, care în timp ne pot aliena iremediabil.  Proiectul de față își propune să arhiveze și să faciliteze viziunea copiilor români și a celor din întreaga lume cu privire la viitorul pe termen scurt, mediu și lung al comunității și al țării în care locuiesc. Cum ar proceda ei dacă ar fi lideri? În contextul în care România împlinește 100 de ani, ne-am propus să invităm 100 de copii să își imagineze următorii 100 de ani. Copiii nu au drept de vot în societatea de astăzi, însă dacă viziunea lor ar fi ascultată și luată în serios măcar uneori, ar putea avea un impact constructiv în comunitatea din care fac parte și în societate pe termen lung. Pe termen lung, proiectul poate fi extins atât temporal (urmărind copiii an de an și urmărind cum fiecare abordare a lor evoluează în timp) cât și spațial - (extinzând proiectul în numeroase alte țări pentru a compara viziunile copiilor în funcție de contextul din care provin) sau tehnologic (utilizând platforme tehnologice multiple pentru a reda aceste viziuni într-o manieră imersivă și interactivă). Un celebru vizionar obișnuia să spună că cea mai bună formă de a prezice viitorul este să-l facem cu mâna noastră. Cred că acest proiect promovează întocmai acest principiu. Este o călătorie cinematografică complexă, care poate fi adresată cu ușurință și cu un impact sporit publicului român și internațional, care poate avea un impact societal constructiv pe termen scurt, mediu și lung.”  - Ioana Mischie',
            team_desc:
                'Echipa proiectului Government of Children 3D reunește un grup transdisciplinar de gânditori vizionari, cineaști, tehnicieni, artiști, eticieni și educatori.'
        }
    }
};

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'ro',
        fallbackLng: 'ro',
        keySeparator: false,

        interpolation: {
            escapeValue: false // not needed for react!!
        },
        react: {
            wait: true
        }
    });

export default i18n;
