import React, { Component } from 'react';
import axios from 'axios';

import { i18n } from '../components/i18n';
import { withTranslation } from 'react-i18next';

import Layout from '../components/layout';
import Constants from '../components/constants';
import { thematics } from '../components/constants';
import FilterComponent from '../components/explore-components/filter-component';
import CanvasBehavior from '../components/background-canvas-component';

import './explore.scss';

let image = require('../images/laughing-child-1-xh39gk.jpg');
let floatingShape = require('../images/shape-explore.png');

const qs = require('query-string');

class ExplorePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: [
                //     { id: 1, childNickname: 'A', videoUrl: 'https://www.w3schools.com/html/mov_bbb.mp4', thumbnail: image },
                //     { id: 2, videoUrl: 'https://www.w3schools.com/html/mov_bbb.mp4', thumbnail: image },
                //     { id: 3, childNickname: 'C', videoUrl: 'https://www.w3schools.com/html/mov_bbb.mp4', thumbnail: image },
                //     { id: 4, childNickname: 'D', videoUrl: 'https://www.w3schools.com/html/mov_bbb.mp4', thumbnail: image },
                //     { id: 5, childNickname: 'E', videoUrl: 'https://www.w3schools.com/html/mov_bbb.mp4', thumbnail: image },
                //     { id: 6, childNickname: 'F', videoUrl: 'https://www.w3schools.com/html/mov_bbb.mp4', thumbnail: image }
            ],
            numberOfPages: 1,
            currentPage: 0,

            currentVideo: {},
            showVideoPlayer: false,
            currentFilter: { thematic: 'All', country: 'All' },
            isFilterVisible: true,
            thematics: thematics,
            filters: undefined
        };

        this.watchVideo = this.watchVideo.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.onFilterVisibilityChanged = this.onFilterVisibilityChanged.bind(this);
        this.onCloseVideoModal = this.onCloseVideoModal.bind(this);
        this.onKeyPressed = this.onKeyPressed.bind(this);

        this.goPrevPage = this.goPrevPage.bind(this);
        this.goNextPage = this.goNextPage.bind(this);

        this._getVideos = this._getVideos.bind(this);
        this._getFilters = this._getFilters.bind(this);
    }

    componentDidMount() {
        this._getFilters();
        this._getVideos();

        document.addEventListener('keyup', this.onKeyPressed, false);

        window.addEventListener('popstate', event => {
            if (this.state.showVideoPlayer) {
                event.preventDefault();
                this.onCloseVideoModal();
            }
        });
    }

    _getFilters() {
        axios.get(Constants.SERVER_ADDRESS + '/filters').then(
            res => {
                this.setState({ filters: res.data });
            },
            err => {
                console.log(err);
            }
        );
    }

    _getVideos() {
        axios
            .get(
                Constants.SERVER_ADDRESS +
                    '/videos?page=' +
                    this.state.currentPage +
                    (this.state.currentFilter.thematic !== 'All'
                        ? '&thematic=' + this.state.currentFilter.thematic
                        : '') +
                    (this.state.currentFilter.country !== 'All' ? '&country=' + this.state.currentFilter.country : '')
            )
            .then(res => {
                const videos = res.data;
                this.setState({ videos: videos.videos, numberOfPages: videos.numberOfPages });
            });
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.onKeyPressed, false);
    }

    watchVideo(video) {
        this.setState({ currentVideo: video, showVideoPlayer: true });
    }

    onFilterChanged(filter) {
        this.setState({ isFilterVisible: true, currentFilter: filter, currentPage: 0 }, () => this._getVideos());
    }

    onFilterVisibilityChanged(status) {
        this.setState({
            isFilterVisible: status
        });
    }

    onCloseVideoModal() {
        this.setState({
            showVideoPlayer: false
        });
    }

    onKeyPressed(event) {
        if (event.keyCode === 27 && this.state.showVideoPlayer) {
            this.onCloseVideoModal();
        }
    }

    goPrevPage() {
        if (this.state.currentPage == 0) return;
        this.setState({ currentPage: this.state.currentPage - 1 }, () => this._getVideos());
    }

    goNextPage() {
        if (this.state.currentPage == this.state.numberOfPages - 1) return;
        this.setState({ currentPage: this.state.currentPage + 1 }, () => this._getVideos());
    }

    render() {
        const { t, i18n } = this.props;

        const listItems = this.state.videos.map(element => (
            <div className="explore-page__video-thumbnail" key={element.id} onClick={() => this.watchVideo(element)}>
                <div className="thumbnail">
                    <img
                        className="thumbnail-duotone"
                        src={element.thumbnail && element.thumbnail !== '' ? element.thumbnail : image}
                        onError={e => {
                            e.target.src = image;
                        }}
                    />
                </div>
                {/* {element.childNickname && (
                    <div className="explore-page__video-title">
                        <span className="circle-underline" />
                        {element.childNickname}
                    </div>
                )} */}
            </div>
        ));

        var videoPlayerStyle = {
            display: 'block'
        };

        return (
            <Layout>
                <div className="explore-page__wrapper">
                    <img className="floating-shape" src={floatingShape} alt="floating shape" />
                    {this.state.videos.length > 0 && <CanvasBehavior />}
                    {this.state.filters && (
                        <FilterComponent
                            thematics={this.state.thematics}
                            filters={this.state.filters}
                            onFilterChanged={this.onFilterChanged}
                            onVisibilityChanged={this.onFilterVisibilityChanged}
                        />
                    )}
                    {qs.parse(this.props.location.search).success === 'true' && <p>{t('success_upload_message')}</p>}
                    <h1>{i18n.language === 'ro' ? 'Explorează' : 'Explore'}</h1>
                    {this.state.currentFilter !== undefined && <h3>{this.state.currentFilter.name}</h3>}

                    <div className={`explore-page__video-list-container ${this.state.isFilterVisible ? '' : 'small'}`}>
                        {listItems}
                    </div>

                    <div className="explore-page__navigator">
                        <div className={`${this.state.currentPage > 0 ? '' : 'disabled'}`} onClick={this.goPrevPage}>
                            &lt;
                        </div>
                        <span>
                            {this.state.currentPage + 1} / {this.state.numberOfPages}{' '}
                        </span>
                        <div
                            className={`${this.state.currentPage < this.state.numberOfPages - 1 ? '' : 'disabled'}`}
                            onClick={this.goNextPage}
                        >
                            &gt;
                        </div>
                    </div>
                    {this.state.showVideoPlayer && (
                        <div className="explore-page__video-player" style={videoPlayerStyle}>
                            <div className="close-but" onClick={this.onCloseVideoModal}>
                                X
                            </div>
                            <video autoPlay controls controlsList="nodownload" src={this.state.currentVideo.videoUrl} />
                        </div>
                    )}
                </div>
            </Layout>
        );
    }
}

export default withTranslation()(ExplorePage);
