import React, { Component } from 'react';
import './filter-component.scss';

class FilterComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFiltering: false,
            currentThematic: 'All',
            currentCountry: 'All'
        };

        this.switchFiltering = this.switchFiltering.bind(this);

        this.onCountrySelected = this.onCountrySelected.bind(this);
        this.onThematicSelected = this.onThematicSelected.bind(this);
        this.updateFilter = this.updateFilter.bind(this);

        this.onThematicSelected = this.onThematicSelected.bind(this);
    }

    switchFiltering() {
        this.setState({
            showFiltering: !this.state.showFiltering
        });

        this.props.onVisibilityChanged(this.state.showFiltering);
    }

    onThematicSelected(event) {
        this.setState({
            currentThematic: event.target.value
        });
    }
    onCountrySelected(event) {
        this.setState({
            currentCountry: event.target.value
        });
    }

    updateFilter(event) {
        event.preventDefault();
        this.setState({ showFiltering: false });
        this.props.onFilterChanged({ thematic: this.state.currentThematic, country: this.state.currentCountry });
    }

    getThematicsSelect(thematics) {
        return (
            <select required onChange={this.onThematicSelected} value={this.state.currentThematic}>
                <option value="all" defaultValue>
                    All Thematics
                </option>
                {thematics.map(thematic => {
                    return (
                        <option key={thematic.key} value={thematic.key} title={thematic.name}>
                            {thematic.name}
                        </option>
                    );
                })}
            </select>
        );
    }

    getCountrySelect(countries) {
        return (
            <select required onChange={this.onCountrySelected} value={this.state.currentCountry}>
                <option value="all" defaultValue>
                    All Countries
                </option>
                {countries.map(country => {
                    return (
                        <option key={country} value={country} title={country}>
                            {country}
                        </option>
                    );
                })}
            </select>
        );
    }

    render() {
        let selectedThematics = this.props.thematics.filter(t => this.props.filters.thematics.includes(t.key));

        return (
            <div className="filter-component__wrapper">
                <div className="pc-version">
                    <button type="button" className={`side-burger`} onClick={this.switchFiltering}>
                        <span className={this.state.showFiltering ? 'icon-x' : 'icon-burger'} />
                    </button>

                    <div className={'side-filter ' + (this.state.showFiltering ? '' : ' closed')}>
                        <form onSubmit={this.updateFilter}>
                            {this.getThematicsSelect(selectedThematics)}
                            {this.getCountrySelect(this.props.filters.countries)}
                            <button type="submit">Apply Filters</button>
                        </form>
                    </div>
                </div>
                <div className="mobile-version">
                    <button type="button" className={`side-burger`} onClick={this.switchFiltering}>
                        <span className={this.state.showFiltering ? 'icon-x' : 'icon-burger'} />
                    </button>

                    <div className={'side-filter ' + (this.state.showFiltering ? '' : ' closed')}>
                        <form onSubmit={this.updateFilter}>
                            {this.getThematicsSelect(selectedThematics)}
                            {this.getCountrySelect(this.props.filters.countries)}
                            {this.state.showFiltering && <button type="submit">Apply Filters</button>}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default FilterComponent;
