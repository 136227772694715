const Constants = {
    // SERVER_ADDRESS: 'http://localhost:8080/'
    SERVER_ADDRESS: 'https://goc.crabdance.com/gocServer/'
};

export const thematics = [
    {
        key: 'LEADERSHIP',
        name: 'Lideri',
        questions: [
            {
                id: 1,
                body: 'Ce înseamnă a fi un lider?'
            },
            { id: 2, body: 'Care sunt calitățile unui lider în opinia ta?' }
        ]
    },
    {
        key: 'GOVERNMENT',
        name: 'Guvern',
        questions: [
            {
                id: 3,
                body:
                    'Dacă ar exista un guvern al copiilor, cum ar arăta? Câți copii ar face parte din el? Ce măsuri ați lua?'
            },
            { id: 4, body: 'Cum ar arăta spațiul fizic sau virtual  în care v-ați întâlni?' },
            {
                id: 5,
                body:
                    'Intr-un guvern exista mai multe ministere, de exemplu Ministerul Educatiei, Ministerul Sanatatii, Ministerul Transporturilor. Dacă ar fi să îți alegi un minister pe care sa il conduci, sau un domeniu de care sa te ocupi ce ti-ai alege? E ceva care te atrage in mod special? E vreun domeniu pe care ți-ar plăcea să îl creezi de la zero?'
            },
            { id: 6, body: 'Dacă tu de mâine ai fi președintele țării care ar fi primul lucru pe care l-ai face?' },
            { id: 7, body: 'Cum ai identifica oamenii cu probleme și cum i-ai ajuta?' },
            { id: 8, body: 'Dacă ai avea resurse nelimitate, ce ai încerca să îmbunătățești în comunitatea/țara ta?' },
            {
                id: 9,
                body:
                    'Cum crezi că poți contribui pentru a face societatea un pic mai bună, chiar dacă nu ai drept de vot?'
            }
        ]
    },
    {
        key: 'ARCHITECTURE',
        name: 'Arhitectură',
        questions: [
            {
                id: 10,
                body: 'Dacă tu ai fi lider, cum ai dezvolta orașul în următorii 100 de ani? '
            },
            { id: 11, body: 'Cum ar arăta clădirile? (descrie-le vizual)' },
            {
                id: 12,
                body: 'Cum ai imagina străzile? (descrie-le vizual)'
            }
        ]
    },
    {
        key: 'EDUCATION',
        name: 'Educație',
        questions: [
            {
                id: 13,
                body: 'Cum ți-ar placea sa arate școala în viitor?'
            },
            { id: 14, body: 'Cum ai îmbunătăți sistemul de educație?' },
            {
                id: 15,
                body: 'Ce materii ai studia și de ce?'
            },
            { id: 16, body: 'Cum ți-ar plăcea să fie profesorii?' }
        ]
    },
    {
        key: 'HEALTHCARE',
        name: 'Medicină',
        questions: [
            { id: 17, body: 'Cum ar arăta un spital al viitorului în opinia ta?' },
            { id: 18, body: 'Dacă un om ar fi bolnav, cum crezi că ar trebui tratat în viitor?' },
            { id: 19, body: 'Cum crezi că vor arăta medicii în viitor?' }
        ]
    },
    {
        key: 'WASTE',
        name: 'Mediu',
        questions: [
            {
                id: 20,
                body:
                    'Cum crezi că am putea opri poluarea? Ce măsuri iei tu în acest sens și ce i-ai îndruma să facă pe oamenii din jurul tău?'
            },
            { id: 21, body: 'Cum crezi că va arăta natura în următorii 100 de ani?' },
            {
                id: 22,
                body: 'Cum crezi că am putea scădea cantitatea de gunoaie?'
            }
        ]
    },
    {
        key: 'NATURE',
        name: 'Natură',
        questions: [
            {
                id: 23,
                body: 'Cum crezi că va arăta natura în viitor?'
            },
            { id: 24, body: 'Cum putem avea grijă mai multă de natură?' }
        ]
    },
    {
        key: 'TRANSPORTATION',
        name: 'Transport',
        questions: [
            { id: 25, body: 'Cum crezi că vor evolua mijloacele de transport în viitor?' },
            {
                id: 26,
                body: 'În prezent este destul de mult trafic. Ce soluții ai propune pentru stoparea traficului?'
            },
            { id: 27, body: 'Crezi ca ar fi util sa călătorim si pe alte planete? De ce?' },
            { id: 28, body: 'Ce ți-ar plăcea să faci pe o altă planetă?' }
        ]
    },
    // { key: 'COSMOS', name: 'On Cosmos' },
    {
        key: 'ECONOMY',
        name: 'Economie',
        questions: [
            {
                id: 29,
                body:
                    'În prezent trăim într-un sistem bazat pe bani (capitalist). Crezi că ai înlocui banii în viitor? Care ar fi moneda de schimb preferată de tine?'
            },
            { id: 30, body: 'Cum ai rezolva problema sărăciei din societățile noastre?' }
        ]
    },
    {
        key: 'TECHNOLOGY',
        name: 'Technologie',
        questions: [
            {
                id: 31,
                body: 'Cum crezi că se va dezvolta tehnologia în viitor?'
            },
            { id: 32, body: 'Ce rol crezi că vor avea roboții în viitor?' },
            { id: 33, body: 'Crezi că avem nevoie de mai multă sau de mai puțină tehnologie?' },
            { id: 34, body: 'Tu cât timp petreci pe telefon/calculator? ' }
        ]
    },
    {
        key: 'CHALLENGES',
        name: 'Provocări',
        questions: [
            {
                id: 35,
                body:
                    'Care sunt cele mai mari probleme din societatea noastră în momentul de față din punctul tău de vedere?'
            },
            {
                id: 36,
                body: 'Cum ce soluții ai găsi pentru problemele contemporane, dacă ai avea putere de decizie?'
            },
            {
                id: 37,
                body: 'Dacă te uiți în jur, ce îi lipsește orașului tău acum?'
            },
            { id: 38, body: 'Cum crezi ca am putea convinge oamenii să nu mai fie violenți?' },
            { id: 39, body: 'Ce am putea face astfel încât să nu mai fie atât de mulți oameni ai străzii?' },
            { id: 40, body: 'Cum crezi că am putea combate depresia?' }
        ]
    },
    {
        key: 'JUSTICE',
        name: 'Justiție',
        questions: [
            {
                id: 41,
                body: 'Cum ai proceda cu cei care nu respecta regulile din societatea pe care o conduci? '
            },
            {
                id: 42,
                body: 'Crezi ca ar trebui să existe pedepse aspre? Ce fel de pedepse sau recompense ai imagina?'
            },
            {
                id: 43,
                body:
                    'De exemplu, ce ai face dacă un alt copil are o idee diferită fata de ideea ta? Cum ați ajunge la un numitor comun?'
            }
        ]
    },
    {
        key: 'ETERNITY',
        name: 'Eternitate',
        questions: [
            { id: 44, body: 'Crezi că oamenii vor putea deveni nemuritori? Ce ar face diferit dacă ar fi nemuritori?' },
            { id: 45, body: 'Cum  crezi că am putea inspira generațiile care vin după noi?' }
        ]
    }
    // { key: 'LONGTERM_PLANNING', name: 'On Long-term planning' }
];

export default Constants;
